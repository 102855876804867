import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { Card, CardActions, CardContent, CardHeader, Container, Grid, LinearProgress, Link, Typography } from "@mui/material/";
import Page from "../../components/page";
import { Api } from "../../shared/api";
import { AppContext } from "../../shared/appContext";
import { DiscordDisplay } from "@dragonsight/models";
import Button from "@mui/material/Button";
import { AddAlert } from "../../shared/alert";

const url = process.env.REACT_APP_DISCORD_AUTH_URL;

type PageState = "MANAGE" | "LINKING";

const DiscordCard = (props: {
    server: DiscordDisplay,
    onDelete: (id: string) => void
}) => {
    return <Card>
        <CardHeader title={props.server.name} />
        <CardContent>
            Hooks:
            {props.server.hooks.map((h) => {
                return <div key={h.channelId}>{h.name} - {h.channelId}</div>
            })}
        </CardContent>
        <CardActions>
            <Button onClick={() => props.onDelete(props.server.serverId)}>Disconnect</Button>
        </CardActions>
    </Card>
}

export const DiscordPage = () => {
    const navigate = useNavigate();
    const userContext = useContext(AppContext);
    const [searchParams] = useSearchParams();
    const guildId = searchParams.get("guild_id");
    const [ pageState, setPageState ] = useState<PageState>(guildId != null ? "LINKING" : "MANAGE");
    const [ servers, setServers] = useState<DiscordDisplay[]>([]);

    const listDiscordServers = async () => {
        const account = userContext.account?.id;

        if(account !== undefined){
            const response = await Api.DiscordServers();
            setServers(response);
        }
    }

    const handleDeleteServer = async (id: string) => {
        const response = await Api.DiscordDelete(id);

        setServers((prev) => prev.filter(s => s.serverId !== response));
    }

    useEffect(() => {
        if(pageState == "LINKING"){
            const addServerCall = async (code: string) => {
                const account = userContext.account?.id;
    
                if(account !== undefined){
                    try {
                        const response = await Api.DiscordAdd(code);
                    } catch(e: any) {
                        AddAlert({ 
                            severity: "error",
                            message: "Server could not be added"
                        })
                    } finally {
                        navigate("/discord");
                        await listDiscordServers();
                        setPageState("MANAGE");
                    }
                }
            }
    
            if(guildId != null) {
                addServerCall(guildId);
            }
        }
        else if (pageState === "MANAGE") {
            
            

            listDiscordServers();
        }
    }, []);

    const content = () => {
        if(pageState === "MANAGE") {
            return <>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    >
                    Servers
                </Typography>
                <Container maxWidth="md" component="main" sx={{ mt: 6, mb: 6 }}>
                    <Grid container spacing={5} alignItems="flex-end">
                        {servers.map((server) => {
                        return <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={server.serverId} 
                            >
                                <DiscordCard
                                    server={server}
                                    onDelete={(id) => handleDeleteServer(id)} />
                            </Grid>
                        })}
                    </Grid>
                </Container>
                <div><Link href={url} variant="button">Add new server</Link></div>
            </>
        }
        if(pageState === "LINKING") {
            return <>
                <h3>Linking your discord</h3>
                <div><LinearProgress /></div>
                </>
        }
    }

    return <Page title="Discord" breadcrumbs={[
        { label: "Home", to: "/"},
        { label: "Discord" }
    ]}>
        {content()}
    </Page>
}