import { Container, CssBaseline, LinearProgress, Link, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AppContext, setCurrentAccount } from './shared/appContext';
import { CognitoUser } from 'amazon-cognito-identity-js';
import AuthPage from './pages/auth';
import { useEffect, useState } from 'react';
import Header from './components/header';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/home';
import { Auth } from 'aws-amplify';
import AccountPage from './pages/account';
import { Api } from './shared/api';
import { Account } from '../../models';
import CreateAccountPage from './pages/account/createAccount';
import { singOut } from './shared/auth';
import { DiscordPage } from './pages/bot/discord';
import AlertBar, { AddAlert } from './shared/alert';
import { theme } from './shared/theme';
import { StripeProcessor } from './shared/processors';
import { HomeDashboardPage } from './pages/game';
import GamePage from './pages/game/game';
import * as SharedAuth from './shared/auth'
import CrmPage from './pages/crm';
import MissingSubscriptionPage from './pages/subscription/missingSubsription';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`© 2022-${new Date().getFullYear()} `}
            <Link color="inherit" href="https://dragonsight.io">DragonSight</Link>
            {`. All rights reserved.`}
        </Typography>
    );
}

type AppState = "AUTH" | "SUBSCRIBE" | "ACCOUNT" | "APP";

const AppContent = (props: { appState: AppState, cognitoUser?: CognitoUser }) => {
    const location = useLocation();
}

function App() {
    const [loading, setLoading] = useState(false);

    const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined);
    const [account, setAccount] = useState<Account | undefined>(undefined);

    const [appState, setAppState] = useState<AppState>("AUTH");

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            try {
                const authedUser = await Auth.currentAuthenticatedUser();
                setCognitoUser(authedUser);
            } catch (e) {
            }         };

        getUser();
    }, []);

    useEffect(() => {
        const call = async () => {

            try {
                const response = await Api.GetAccount();
                setAccount(response);
                setCurrentAccount(response!);
                const responseAccount: Account = response!;
                if(responseAccount.stripeSubscriptionId === undefined){
                    setAppState("SUBSCRIBE");
                }
                else{
                    setAppState("APP");
                }
            } catch (e: any) {
                if(e.response?.data?.code === "NO_ACCOUNT") {
                    setAppState("ACCOUNT");
                } else {
                    AddAlert({
                        message: e.message,
                        severity: "error"
                    })
                }
            } finally {
                setLoading(false);
            }
        };
        call();
    }, [cognitoUser]);

    const handleSignout = async () => {
        await singOut();
        setAppState("AUTH");
        setCognitoUser(undefined);
        setAccount(undefined);
        SharedAuth.singOut();
    };

    if (loading) {
        return <LinearProgress />;
    }

    const Content = () => {
        const location = useLocation();
        if(location.pathname === "/stripe"){
            return <StripeProcessor />
        }
        if (appState === "AUTH") {
            return (<Container maxWidth="xs"><AuthPage onUserSignIn={(user) => setCognitoUser(user)}
            page="SignIn"/></Container>);
        } else if (appState === "ACCOUNT") {
            return (<Container maxWidth="xs"><CreateAccountPage /></Container>);
        } else if (appState === "SUBSCRIBE") {
            return (<Container maxWidth="xs" component="main"><MissingSubscriptionPage  /></Container>)
        } else if (cognitoUser !== undefined && account !== undefined) {
            return (
                <AppContext.Provider
                value={{ account: account, user: {username: cognitoUser.getUsername() }, signout: handleSignout, changeAccount: () => {} }}>
                        <Header sections={[
                            { title: "Home", url: "/" },
                            { title: "Integrations", url: "/integrations"},
                            { title: "Crm", url: "/crm" },
                            { title: "Account", url: "/account" }
                        ]}/>
                        <Container component="main" maxWidth="lg" sx={{ pt: 8, pb: 6 }}>
                            <Routes>
                                <Route path="account" element={<AccountPage/>}/>
                                <Route path="integrations" element={<DiscordPage/>}/>
                                <Route path="game/:gameId" element={<GamePage/>}/>
                                <Route path="stripe" element={<StripeProcessor />}/>
                                <Route path="crm" element={<CrmPage/>}></Route>
                                <Route path="billing/cancel"></Route>
                                <Route index element={<HomeDashboardPage/>}/>
                            </Routes>
                        </Container>
                </AppContext.Provider>
            );
        } else {
            return <></>
        }
    };
    
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AlertBar/>
            <BrowserRouter>
                <Content />
            </BrowserRouter>
            <Copyright sx={{ mt: 5 }}/>
        </ThemeProvider>
    );
}

export default App;
