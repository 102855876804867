import { Game, GameFeedback, GameFeedbackChannel } from "@dragonsight/models";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Icon, LinearProgress, Paper, Stack, Switch, Tab, TablePagination, Tabs, ToggleButton, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextInput } from "../../components/form";
import Page from "../../components/page";
import { AddAlert } from "../../shared/alert";
import { Api } from "../../shared/api";

import BookmarkIcon from '@mui/icons-material/Bookmark';
import Section from "../../components/section";

interface GameValues {
    name: string;
    description?: string;
    notes?: string;
    twitchId?: string;
    youtubeId?: string;
}

const UpdateDialog = (props: {
    show: boolean,
    game: Game,
    onClose: () => void,
    onGameAdded: (game: Game) => void,
}) => {
    const [ submitting, setSubmitting ] = useState(false);
    const { game, show, onClose, onGameAdded } = props;

    const AddGame = async (values: GameValues) => {
        setSubmitting(true);
        try {
            const response = await Api.GameUpdate({
                id: game.id,
                ...values
            });
            AddAlert({
                severity: "success",
                message: `${response.name} has been updated.`
            })
            onGameAdded(response);
            onClose();
        } catch(e:any) {
            AddAlert({
                severity: "error",
                message: e.message,
            })
        } finally {
            setSubmitting(false);
        }
    }

    return <Dialog open={show} onClose={() => onClose()}>
    <DialogTitle>Update game</DialogTitle>
        <Formik initialValues={{ name: game.name, description: game.description, notes: game.notes, twitchId: game.twitchId }}
            onSubmit={(values) => AddGame(values)}>
            {({
                handleSubmit, values
            }) => (<>
                <DialogContent>
                    <DialogContentText>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                    <TextInput
                                        label="name"
                                        name="name"
                                        type="text"
                                        disabled={submitting}
                                        required
                                        />
                                </Grid>

                                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                                    <TextInput
                                        label="Description"
                                        name="description"
                                        type="text"
                                        disabled={submitting}
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Notes"
                                        name="notes"
                                        type="text"
                                        disabled={submitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="TwitchId"
                                        name="twitchId"
                                        type="text"
                                        disabled={submitting}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button onClick={() => handleSubmit()}>Update</Button>
                </DialogActions>
            </>)}
        </Formik>
    </Dialog>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`game-tabpanel-${index}`}
        aria-labelledby={`game-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Paper sx={{ mt: 2, p: 3 }}>
            <Typography>{children}</Typography>
          </Paper>
        )}
      </div>
    );
  }

const FeedbackChannels = () => {
    const { gameId } = useParams();
    const [ channels, setChannels ] = useState<GameFeedbackChannel[]>([]);
    const [ loading, setLoading ] = useState(true);

    const feedbackUrl = "https://dragonsight.io/postfeedback/";

    const getChannels = async (token?: string) => {
        setLoading(true);
        try {
            const result = await Api.Game.FeedbackChannelList(gameId!, undefined, undefined);
            setChannels(result.items);
            //setNextToken(result.nextToken);
        } catch(e: any) {
    
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getChannels(undefined);
    }, []);

    const addFeedbackChannel = async (values: { name: string }) => {
        const channel = await Api.Game.FeedbackChannelAdd(gameId!, values.name);

        setChannels((current) => {
            return [ ...current, channel];
        })
    }

    return <Section header="Channels" collapse>
        <Stack spacing={2} marginBottom={1}>{channels.map((channel) => {
            return <Grid container key={channel.id}>
                <Grid item xs={3}>{channel.name}</Grid>
                <Grid item xs={9}>URL: {feedbackUrl}{channel.id}</Grid>
            </Grid>
        })}</Stack>
        <Formik initialValues={{name: ""}} onSubmit={(values) => addFeedbackChannel(values)}>
        {({
            handleSubmit, values
        }) => (<>
            <TextInput
                                    label="Name"
                                    name="name"
                                    type="text"
                                    disabled={false}
                                />
            <Button onClick={() => handleSubmit()}>Add Channel</Button>
        </>)}
        </Formik>
    </Section>
}

const FeedbackItems = () => {
    const theme = useTheme();
    const { gameId } = useParams();

    const [ items, setItems] = useState<GameFeedback[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ nextToken, setNextToken ] = useState<string>();

    const getItems = async (token?: string) => {
        setLoading(true);
        try {
            const result = await Api.Game.ListFeedback(gameId!, undefined, undefined);
            setItems(result.items);
            setNextToken(result.nextToken);
        } catch(e: any) {
    
        } finally {
            setLoading(false)
        }
    }

    const loadMore = () => {
        getItems(nextToken);
    }

    const refresh = () => {
        getItems(undefined);
    }

    const setFlagged = async (id: string, flagged: boolean) => {
        await Api.Game.EditFeedback(id, flagged);
        //edit locally
        setItems((old) => {
            const newItems = [...old]
            const index = newItems.findIndex((f) => f.id === id);
            newItems[index].flagged = flagged;
            return newItems;
        })
    }

    useEffect(() => {
        getItems(undefined);
    }, []);

    if(loading){
        return <>Loading</>
    }

    return <>
        <FeedbackChannels />

        <Section header="Feedback">
            <Box textAlign={"right"}><FormControlLabel control={<Switch value={false} />} label="Show flagged only" /></Box>
            <Stack spacing={4}>
                {items.map((feedback) => {
                    return <Box key={feedback.id}>
                        <Typography fontSize={12}>
                            {new Date(feedback.createdAt).toDateString()}
                            <BookmarkIcon onClick={() => setFlagged(feedback.id, !feedback.flagged)} sx={{ fontSize: 14, verticalAlign: "top" }} color={feedback.flagged ? "info" : "disabled"} /></Typography>
                        <Box marginY={1}>{feedback.content}</Box>
                        <Stack direction="row" spacing={1}>
                            {feedback.tags !== undefined && feedback.tags.map((tag) => {
                                return <Chip key={tag} label={tag} variant="outlined" />;
                            })}
                        </Stack>
                    </Box>
                })}
            </Stack>
        </Section>
        {nextToken !== undefined && <Button onClick={() => loadMore()}>Load More</Button>}
    </>
}

const GamePage = () => {
    const navigate = useNavigate();
    const { gameId } = useParams();
    const [loading, setLoading] = useState(false);
    const [game, setGame] = useState<Game | undefined>(undefined);
    const [ showUpdate, setShowUpdate ] = useState(false);
    const [ tab, setTab] = useState(0);


    useEffect(() => {
        const call = async () => {
            try {
                setLoading(true);
                const response = await Api.GameRead(gameId!);
                setGame(response);
            } catch(e: any) {
                AddAlert({ severity: "error", message: "Unable to get game."})
                navigate("/game");
            } finally {
                setLoading(false);
            }
        }
        call();
    }, [])

    if(loading || game === undefined){
        return <LinearProgress></LinearProgress>
    }

    const removeGame = async () => {
        try {
            await Api.GameDelete(gameId!);
            AddAlert({ severity: "success", message: `${game.name} has been removed from your account.`})
            navigate("/game");
        } catch(e: any) {
            AddAlert({ severity: "success", message: `${game.name} was not able to be deleted.`})
        } finally {

        }
    };

    function a11yProps(index: number) {
        return {
          id: `gmae-tab-${index}`,
          'aria-controls': `game-tabpanel-${index}`,
        };
      }

    return <Page title={game.name} breadcrumbs={[
        { label: "Home", to: "/"},
        { label: "Game", to: "/game"},
        { label: game.name }
    ]}>
        <UpdateDialog show={showUpdate} game={game} onClose={() => setShowUpdate(false)} onGameAdded={(game) => setGame(game)} />
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={(event, value) => setTab(value)} aria-label="Game Page tabs">
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Feedback" {...a11yProps(1)} />
            <Tab label="Streams" {...a11yProps(2)} />
            <Tab label="Settings" {...a11yProps(3)} />
        </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
            <Grid container spacing={1}>
                <Grid item md={3} xs={12}><Typography fontSize={12}>Description</Typography></Grid>
                <Grid item md={9} xs={12}>{game.description}</Grid>
                <Grid item md={3} xs={12}><Typography fontSize={12}>Notes</Typography></Grid>
                <Grid item md={9} xs={12}>{game.notes}</Grid>
            </Grid>
            
            <Stack spacing={1} marginTop={2} direction="row">
                <Button variant="contained" onClick={() => setShowUpdate(true)}>Update</Button>
                <Button variant="contained" onClick={() => removeGame()}>Remove game</Button>
            </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
            <FeedbackItems />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
            Currently not implemented
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={3}>
            <Box>Game Feedback url: https://desgonsight.io/api/feedback/{game.id}</Box>
        </CustomTabPanel>
    </Page>
}

export default GamePage;
